import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Exo 2, sans-serif",
    h1: { fontFamily: "Exo 2, sans-serif" },
    h2: { fontFamily: "Exo 2, sans-serif" },
    h3: { fontFamily: "Exo 2, sans-serif" },
    h4: { fontFamily: "Exo 2, sans-serif" },
    h5: { fontFamily: "Exo 2, sans-serif" },
    h6: { fontFamily: "Exo 2, sans-serif" },
    subtitle1: { fontFamily: "Exo 2, sans-serif" },
    subtitle2: { fontFamily: "Exo 2, sans-serif" },
    body1: { fontFamily: "Exo 2, sans-serif" },
    body2: { fontFamily: "Exo 2, sans-serif" },
    button: { fontFamily: "Exo 2, sans-serif", textTransform: "none" },
    caption: { fontFamily: "Exo 2, sans-serif" },
    overline: { fontFamily: "Exo 2, sans-serif" },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@400;700&display=swap');
        body {
          font-family: 'Exo 2', sans-serif;
        }
        button {
          font-family: 'Exo 2', sans-serif !important;
        }
      `,
    },
  },
});

export default theme;
