import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/axios";

export const checkPremium = createAsyncThunk("checkPremium", async () => {
  try {
    const response = await api.post("api/v1/iaps/in-app-user/check");

    return response.data.data;
  } catch (error) {
    console.error("Error when check premium:", error);
  }
});
