import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncActionsMatcher,
  createAsyncActionsHandler,
} from "./handleAsyncAction";
import { checkSubscription } from "../api";

const asyncThunks = [{ source: checkSubscription }];

const userSubscriptionInfo = createSlice({
  name: "subscription",
  initialState: {
    data: null,
    status: null,
    error: null,
  },
  reducers: {
    resetSubscriptionState: (state) => {
      state.data = null;
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      createAsyncActionsMatcher(asyncThunks),
      createAsyncActionsHandler(asyncThunks)
    );
  },
});

export const { resetSubscriptionState } = userSubscriptionInfo.actions;
export default userSubscriptionInfo.reducer;
