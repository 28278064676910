import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import vehicleHistoryReducer from "./slices/vehicleHistory";
import userProfileReducer from "./slices/userProfile";
import userSubscriptionInfoReducer from "./slices/userSubscriptionInfo";
import userPremiumInfoReducer from "./slices/userPremiumInfo";
import stripeConfigReducer from "./slices/stripeConfig";
import expireIn from "redux-persist-transform-expire-in";

const expireSeconds = 900000;

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "vehicleHistory",
    "userProfile",
    "userSubscriptionInfo",
    "userPremiumInfo",
    "stripeConfig",
  ],
  transforms: [
    expireIn(expireSeconds, "vehicleHistory"),
    expireIn(expireSeconds, "userProfile"),
    expireIn(expireSeconds, "userSubscriptionInfo"),
    expireIn(expireSeconds, "userPremiumInfo"),
    expireIn(expireSeconds, "stripeConfig"),
  ],
};

const rootReducer = combineReducers({
  vehicleHistory: vehicleHistoryReducer,
  userProfile: userProfileReducer,
  userSubscriptionInfo: userSubscriptionInfoReducer,
  userPremiumInfo: userPremiumInfoReducer,
  stripeConfig: stripeConfigReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Persist store
export const persistor = persistStore(store);
export default store;
