import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";

export const DrawerContent = ({
  handleNavClick,
  handleGetStartedClick,
  handleLoginClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        marginTop: "12px",
      }}
    >
      <List sx={{ width: "100%" }}>
        {["Features", "How it works", "Pricing", "FAQs"].map((text) => (
          <ListItem
            button
            key={text}
            onClick={() =>
              handleNavClick(`#${text.replace(/ /g, "").toLowerCase()}`)
            }
            sx={{ paddingLeft: 0 }}
          >
            <ListItemText
              primary={text}
              primaryTypographyProps={{ fontSize: 14, fontWeight: 600 }}
              sx={{ fontColor: "#FFFFFF", fontSize: "14px", fontWeight: 600 }}
            />
          </ListItem>
        ))}
        <ListItem button sx={{ padding: 0, mt: 3, width: "100%" }}>
          <Button
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "14px",
              border: "none",
              backgroundColor: "#3772FF",
              width: "100%",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0650CA",
              },
            }}
            onClick={handleGetStartedClick}
          >
            Get started
          </Button>
        </ListItem>
        <ListItem
          button
          onClick={handleLoginClick}
          sx={{ paddingLeft: 0, marginTop: "8px" }}
        >
          <ListItemText
            primary="Login"
            primaryTypographyProps={{ fontSize: 14, fontWeight: 600 }}
            sx={{ display: "flex", justifyContent: "center" }}
          />
        </ListItem>
      </List>
    </Box>
  );
};
