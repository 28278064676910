// handleAsyncActions.js
export const createAsyncActionsMatcher = (asyncThunks) => (action) => {
  const types = asyncThunks.reduce((acc, asyncThunk) => {
    acc.push(
      asyncThunk.source.pending.type,
      asyncThunk.source.fulfilled.type,
      asyncThunk.source.rejected.type
    );
    return acc;
  }, []);

  return types.includes(action.type);
};

export const createAsyncActionsHandler = (asyncThunks) => (state, action) => {
  const asyncThunk = asyncThunks.find(
    (thunk) =>
      thunk.source.pending.type === action.type ||
      thunk.source.fulfilled.type === action.type ||
      thunk.source.rejected.type === action.type
  );

  switch (action.type) {
    case asyncThunk.source.pending.type:
      handlePending(state);
      break;
    case asyncThunk.source.fulfilled.type:
      if (!asyncThunk.data) {
        handleFulfilled(state, action);
      } else {
        handleFulfilled(state, action, asyncThunk.data);
      }
      break;
    case asyncThunk.source.rejected.type:
      handleRejected(state, action);
      break;
    default:
      break;
  }
};

const handlePending = (state) => {
  state.status = "loading";
  state.error = null;
};

const handleFulfilled = (state, action, data) => {
  state.status = "succeed";
  if (!data) {
    state.data = action.payload;
  } else {
    state.data[data] = action.payload.data;
  }
  state.error = null;
};

const handleRejected = (state, action) => {
  state.status = "failed";
  state.error = action.error.message;
};
