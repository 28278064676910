import api from "../interceptors/axios";

export const getVehicles = async () => {
  try {
    const response = await api.get(
      "api/v3/vehicles?populate=serviceReminders&isSelected=true"
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const getVehicleId = async (id) => {
  try {
    const response = await api.get(`api/v3/vehicles/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const putVehicleSelected = async (carId) => {
  try {
    const response = await api.put(`api/v3/vehicles/${carId}/selected`);

    return response;
  } catch (error) {
    throw error;
  }
};

export const getVehicleManual = async (make, model, year) => {
  try {
    const response = await api.get(
      `api/v3/vehicles/manual?make=${make}&model=${model}&year=${year}`
    );

    return response;
  } catch (error) {
    throw error;
  }
};
