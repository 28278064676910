import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";
import Container from "@mui/material/Container";
import Collapse from "@mui/material/Collapse";
import { DrawerContent } from "./DrawerContent";
import logoImage from "../../assets/images/logo.png";

const MenuToggle = ({ isOpen, toggleMenu, elevate, isSupportPage }) => {
  const color = elevate || isOpen || isSupportPage ? "#021056" : "#FFFFFF";

  return (
    <div
      onClick={toggleMenu}
      style={{
        cursor: "pointer",
        width: "18px",
        height: "18px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "18px",
          height: "2px",
          backgroundColor: color,
          position: "absolute",
          top: isOpen ? "6px" : "5px",
          transform: isOpen ? "rotate(45deg)" : "rotate(0)",
          transition: "all 0.3s ease-in-out",
        }}
      ></div>
      <div
        style={{
          width: "18px",
          height: "2px",
          backgroundColor: color,
          position: "absolute",
          top: isOpen ? "6px" : "13px",
          transform: isOpen ? "rotate(-45deg)" : "rotate(0)",
          transition: "all 0.3s ease-in-out",
        }}
      ></div>
    </div>
  );
};

export const Header = ({ isSupportPage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [elevate, setElevate] = useState(false);

  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prevOpen) => !prevOpen);
  }, []);

  const handleLoginClick = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const handleGetStartedClick = useCallback(() => {
    navigate("/get-started");
  }, [navigate]);

  const handleNavClick = useCallback((hash) => {
    const section = document.querySelector(hash);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setDrawerOpen((prevOpen) => !prevOpen);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setElevate(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (location.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: {
          xs:
            drawerOpen || elevate || isSupportPage ? "#FFFFFF" : "transparent",
          lg: elevate || isSupportPage ? "#FFFFFF" : "transparent",
        },
        color: "#021056",
        boxShadow:
          elevate || isSupportPage
            ? "0 32px 64px -32px rgba(31,47,70,0.3)"
            : "none",
        padding: { xs: "8px 0", md: "16px 0", lg: "20px 0" },
        zIndex: theme.zIndex.drawer + 1,
        width: "100%",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallDevice ? "column" : "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: isSmallDevice ? "center" : "flex-start",
              width: isSmallDevice ? "100%" : "none",
            }}
          >
            {isSmallDevice ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                  >
                    <Box
                      component="img"
                      src={logoImage}
                      alt="Logo"
                      sx={{
                        width: "48px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleNavClick(`#hero`)}
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        fontSize: { xs: "18px", lg: "20px" },
                        fontWeight: "600",
                        color:
                          elevate || drawerOpen || isSupportPage
                            ? "#021056"
                            : "#FFFFFF",
                      }}
                    >
                      CarPass Digital Car Key
                    </Typography>
                  </Box>

                  <MenuToggle
                    isOpen={drawerOpen}
                    toggleMenu={handleDrawerToggle}
                    elevate={elevate}
                    isSupportPage={isSupportPage}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  component="img"
                  src={logoImage}
                  alt="Logo"
                  sx={{
                    width: "48px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavClick(`#hero`)}
                />
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: { xs: "18px", lg: "20px" },
                    fontWeight: "600",
                    ml: "8px",
                    color: elevate || isSupportPage ? "#021056" : "#FFFFFF",
                  }}
                >
                  CarPass Digital Car Key
                </Typography>
              </>
            )}
          </Box>

          {!isSmallDevice && (
            <Box
              sx={{
                display: "flex",
                gap: 5,
                flexGrow: 1,
                justifyContent: "center",
              }}
            >
              {["Features", "How it works", "Pricing", "FAQs"].map((text) => (
                <Button
                  key={text}
                  color="inherit"
                  sx={{
                    fontColor: "#021056",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: elevate || isSupportPage ? "#021056" : "#FFFFFF",
                  }}
                  onClick={() =>
                    handleNavClick(`#${text.replace(/ /g, "").toLowerCase()}`)
                  }
                >
                  {text}
                </Button>
              ))}
            </Box>
          )}

          {!isSmallDevice && (
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Button
                color="inherit"
                sx={{
                  fontWeight: "600",
                  fontSize: "14px",
                  border: "2px solid #E6E8EC",
                  width: "89px",
                  height: "48px",
                  borderRadius: "6px",
                  color: elevate || isSupportPage ? "#021056" : "#FFFFFF",
                }}
                onClick={handleLoginClick}
              >
                Login
              </Button>
              <Button
                sx={{
                  color: "#FFFFFF",
                  fontWeight: "600",
                  fontSize: "14px",
                  border: "none",
                  width: "137px",
                  backgroundColor: "#0764FD",
                  height: "48px",
                  "&:hover": {
                    backgroundColor: "#0650CA",
                  },
                  borderRadius: "6px",
                }}
                onClick={handleGetStartedClick}
              >
                Get started
              </Button>
            </Box>
          )}

          {isSmallDevice && (
            <Collapse
              in={drawerOpen}
              timeout={{
                enter: 200,
                exit: 100,
              }}
              easing={{
                enter: "ease-out",
                exit: "ease-in",
              }}
              unmountOnExit
              fullWidth
              sx={{ width: "100%" }}
            >
              <DrawerContent
                handleNavClick={handleNavClick}
                handleGetStartedClick={handleGetStartedClick}
                handleLoginClick={handleLoginClick}
              />
            </Collapse>
          )}
        </Box>
      </Container>
    </AppBar>
  );
};
