import { createSlice } from "@reduxjs/toolkit";
import { getStripeConfigs } from "../api";
import {
  createAsyncActionsMatcher,
  createAsyncActionsHandler,
} from "./handleAsyncAction";

const asyncThunks = [{ source: getStripeConfigs }];

const stripeConfigSlice = createSlice({
  name: "stripeConfig",
  initialState: {
    data: null,
    status: null,
    error: null,
  },
  reducers: {
    resetConfigState: (state) => {
      state.data = null;
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      createAsyncActionsMatcher(asyncThunks),
      createAsyncActionsHandler(asyncThunks)
    );
  },
});

export const { resetConfigState } = stripeConfigSlice.actions;
export default stripeConfigSlice.reducer;
