export const truncateToTwoDecimals = (num) => {
  return Math.floor(num * 100) / 100;
};

export const formatDateFromISOString = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};
