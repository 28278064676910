import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncActionsMatcher,
  createAsyncActionsHandler,
} from "./handleAsyncAction";
import { checkPremium } from "../api";

const asyncThunks = [{ source: checkPremium }];

const userPremiumInfo = createSlice({
  name: "premium",
  initialState: {
    data: null,
    status: null,
    error: null,
  },
  reducers: {
    resetPremiumState: (state) => {
      state.data = null;
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      createAsyncActionsMatcher(asyncThunks),
      createAsyncActionsHandler(asyncThunks)
    );
  },
});

export const { resetPremiumState } = userPremiumInfo.actions;
export default userPremiumInfo.reducer;
