import { createSlice } from "@reduxjs/toolkit";
import {
  createAsyncActionsMatcher,
  createAsyncActionsHandler,
} from "./handleAsyncAction";
import { fetchHistoryInfo, fetchHistoryRecall } from "../api"

const asyncThunks = [
  { source: fetchHistoryInfo, data: "historyInfo" },
  { source: fetchHistoryRecall, data: "historyRecall" },
];

const vehicleHistory = createSlice({
  name: "vehicleHistory",
  initialState: {
    data: {
      historyInfo: null,
      historyRecall: null,
    },
    status: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      createAsyncActionsMatcher(asyncThunks),
      createAsyncActionsHandler(asyncThunks)
    );
  },
});

export default vehicleHistory.reducer;
