export const getStylesForScreenSize = (isXSmallScreen, isSmallScreen) => {
  const boxWidth = isXSmallScreen ? "100%" : "432px";
  const boxColor = isXSmallScreen ? "white" : "#F2F2F4";
  const paddingBox = isSmallScreen ? "20px" : "16px";
  const boxColorChild = isXSmallScreen ? "#F2F2F4" : "#FFFFFF";

  return {
    boxWidth,
    boxColor,
    boxColorChild,
    paddingBox,
  };
};

export const getStylesForScreenSizeAuth = (
  isXSmallScreen,
  isSmallScreen,
  isMediumScreen,
  isLoginPage
) => {
  const boxWidth = isLoginPage
    ? isXSmallScreen
      ? "90%"
      : isSmallScreen
      ? "70%"
      : isMediumScreen
      ? "50%"
      : "30%"
    : isXSmallScreen
    ? "90%"
    : isSmallScreen
    ? "80%"
    : isMediumScreen
    ? "70%"
    : "50%";
  const percentPadding = isXSmallScreen
    ? "35%"
    : isSmallScreen
    ? "25%"
    : isMediumScreen
    ? "15%"
    : "10%";

  return {
    boxWidth,
    percentPadding,
  };
};
