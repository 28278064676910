import api from "../interceptors/axios";

export const callAgents = async () => {
  try {
    const response = await api.get(`api/v3/callAgents`);

    return response;
  } catch (error) {
    throw error;
  }
};
