import React from "react";
import { Container, Box } from "@mui/material";

const ResponsiveLayout = ({ children, isMobile, isTablet }) => {
  return (
    <Container
      disableGutters={isMobile || isTablet}
      maxWidth={isMobile ? "100%" : "lg"}
    >
      <Box
        sx={{
          padding: isMobile ? "0 16px" : isTablet ? "0 20px" : 0,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

export default ResponsiveLayout;
