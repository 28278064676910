import axios from "axios";
import config from "../config";
import {
  getToken,
  removeToken,
  setToken,
  getRefreshToken,
  removeRefreshToken,
} from "../utils";
import { refreshToken } from "../api";

const api = axios.create({
  baseURL: config.apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

const removeInfoUser = () => {
  removeToken();
  removeRefreshToken();
};

let refresh = false;

// Intercept all requests
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      // Update token for api header
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !refresh) {
      refresh = true;

      try {
        const response = await refreshToken(getRefreshToken());
        if (response.status === 200) {
          const newAccessToken = response.data.token;

          // Update token for api header
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          // Store token to cookies
          setToken(newAccessToken);

          return api(originalRequest);
        }
      } catch (refreshError) {
        removeInfoUser();
        window.location.href = "/login";
        refresh = false;
        return Promise.reject(refreshError);
      }
    }
    refresh = false;
    return Promise.reject(error);
  }
);

export default api;
