export const getStartTime = () => {
  return localStorage.getItem("startTime");
};

export const removeStartTime = () => {
  localStorage.removeItem("startTime");
};

export const setStartTime = (time) => {
  localStorage.setItem("startTime", time);
};

export const getEmail = () => {
  return localStorage.getItem("email");
};

export const setEmail = (email) => {
  localStorage.setItem("email", email);
};

export const removeEmail = () => {
  localStorage.removeItem("email");
};

export const setItemWithExpiry = (key, value, ttl = 24 * 60 * 60 * 1000) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};
