import api from "../interceptors/axios";

export const getServices = async (id) => {
  try {
    const response = await api.get("/api/v3/serviceReminders", {
      params: {
        vehicle: id,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const addService = async (data) => {
  try {
    const response = await api.post("/api/v3/serviceReminders", data);

    return response;
  } catch (error) {
    throw error;
  }
};


export const updateService = async (id, data) => {
  try {
    const response = await api.put(`/api/v3/serviceReminders/${id}`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const removeService = async (id) => {
  try {
    const response = await api.delete(`/api/v3/serviceReminders/${id}`);

    return response;
  } catch (error) {
    throw error;
  }
};