import api from "../interceptors/axios";

export const carApi = {
  checkCarLockStatus: async (id, token) => {
    return handleApiRequest(() =>
      api.get(`api/v3/remotes/cars/vehicles/${id}/security?token=${token}`)
    );
  },

  lockCar: async (id, token) => {
    return handleApiRequest(() =>
      api.post(`api/v3/remotes/cars/vehicles/${id}/lock?token=${token}`)
    );
  },

  unLockCar: async (id, token) => {
    return handleApiRequest(() =>
      api.post(`api/v3/remotes/cars/vehicles/${id}/unlock?token=${token}`)
    );
  },

  checkCarToken: async (token) => {
    return handleApiRequest(() =>
      api.get(`api/v3/car-models/checkCompatible?code=${token}`)
    );
  },

  getCarVehicleId: async (vehicleId) => {
    return handleApiRequest(() =>
      api.get(`api/v3/remotes/cars/vehicles/${vehicleId}`)
    );
  },

  getCarVehicleToken: async () => {
    return handleApiRequest(() => api.get(`api/v3/remotes/cars/vehicles`));
  },

  getCarExchange: async (code) => {
    return handleApiRequest(() =>
      api.get(`api/v3/remotes/cars/exchange/web?code=${code}`)
    );
  },

  getCarsList: async () => {
    return handleApiRequest(() => api.get(`api/v3/vehicles`));
  },

  disConnectCar: async (vehicleId) => {
    return handleApiRequest(() =>
      api.post(`api/v3/remotes/cars/vehicles/${vehicleId}/disconnect`)
    );
  },

  deleteCar: async (carId) => {
    return handleApiRequest(() => api.delete(`api/v3/vehicles/${carId}`));
  },

  fetchBrands: async () => {
    return handleApiRequest(() => api.get("api/v3/brands?sort=name"));
  },

  fetchModels: async (brandName) => {
    return handleApiRequest(() =>
      api.get(`api/v3/car-models?model=${brandName}`)
    );
  },
};

const handleApiRequest = async (apiFunction) => {
  try {
    const response = await apiFunction();
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};
