import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "@mui/material";
import { aboveIcons, belowIcons } from "../../utils/carSlideIcons";

const IconMarquee = ({ icons, direction }) => {
  const [loadedIcons, setLoadedIcons] = useState([]);

  useEffect(() => {
    Promise.all(icons).then((resolvedIcons) => {
      setLoadedIcons(resolvedIcons);
    });
  }, [icons]);

  return (
    <Marquee
      speed={100}
      gradient={true}
      style={{ width: "100%", overflow: "hidden", display: "flex" }}
      delay={0}
      direction={direction}
      loop={0}
      autoFill={true}
      pauseOnClick={true}
    >
      {loadedIcons.map((icon, index) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            width: { xs: "84px", md: "180px" },
            height: { xs: "46px", md: "98px" },
            overflow: "hidden",
            marginRight: { xs: "11px", md: 3 },
            marginLeft: { xs: "11px", md: 3 },
            boxSizing: "border-box",
          }}
          key={index}
        >
          <Box
            sx={{
              width: { xs: "47px", md: "70px", lg: "100px" },
              height: { xs: "19px", md: "28px", lg: "40px" },
            }}
          >
            <img
              alt={`car-brand-${index + 1}`}
              src={icon.default}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Box>
        </Box>
      ))}
    </Marquee>
  );
};

const AnimatedIconMarquee = ({ icons, direction, delay }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return isLargeScreen ? (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={inView ? { opacity: 1, scale: 1 } : {}}
      transition={{ duration: 0.8, delay, ease: "easeOut" }}
    >
      <IconMarquee icons={icons} direction={direction} />
    </motion.div>
  ) : (
    <IconMarquee icons={icons} direction={direction} />
  );
};

const AnimatedText = ({ text, delay }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return isLargeScreen ? (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={inView ? { opacity: 1, scale: 1 } : {}}
      transition={{ duration: 0.8, delay, ease: "easeOut" }}
    >
      <Typography
        sx={{
          color: "#021056",
          fontSize: { xs: "16px", md: "20px" },
          fontWeight: "600",
          lineHeight: { xs: "28px", md: "48px" },
          textAlign: "center",
          margin: "0 auto",
          width: "100%",
        }}
      >
        {text}
      </Typography>
    </motion.div>
  ) : (
    <Typography
      sx={{
        color: "#021056",
        fontSize: { xs: "16px", md: "20px" },
        fontWeight: "600",
        lineHeight: { xs: "28px", md: "48px" },
        textAlign: "center",
        margin: "0 auto",
        width: "100%",
      }}
    >
      {text}
    </Typography>
  );
};

export const CarSlides = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: { xs: "20px", md: "30px", lg: "40px" },
        padding: { xs: "24px 0", md: "48px 0", lg: "76px 0" },
        paddingTop: { xs: "70px", md: "132px", lg: "152px" },
      }}
    >
      <AnimatedText text="Compatible with 40+ car makes" delay={0} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "8px", md: "18px" },
        }}
      >
        <AnimatedIconMarquee icons={aboveIcons} direction="left" delay={0.2} />
        <AnimatedIconMarquee icons={belowIcons} direction="right" delay={0.4} />
      </Box>
    </Box>
  );
};
