import api from "../interceptors/axios";

export const requestOtp = async (email) => {
  try {
    const response = await api.post("api/v1/auth/request-otp-login", {
      email: email,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const compareOtp = async (email, otp) => {
  try {
    const response = await api.post("api/v1/auth/compare-otp", {
      email: email,
      otpRequest: otp,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const login = async (email, endpoint) => {
  try {
    const response = await api.post(`api/v1/auth/${endpoint}`, {
      email: email,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const oneTimeLogin = async (code) => {
  try {
    const response = await api.post(
      `api/v3/auth/one-time-login`, { code }
    );
    return response;
  } catch (err) {
    throw err;
  }
};

export const checkEmail = async (email) => {
  try {
    const response = await api.post(`api/v1/auth/check-email`, {
      email: email,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const loginSocial = async (type, access_token) => {
  try {
    const response = await api.post(`api/v1/auth/login/${type.toLowerCase()}`, {
      access_token,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const signupSocial = async (type, access_token) => {
  try {
    const response = await api.post(`api/v1/auth/signup/${type.toLowerCase()}`, {
      access_token,
    });

    return response;
  } catch (error) {
    throw error;
  }
};

export const refreshToken = async (refreshToken) => {
  try {
    const response = await api.post("api/v1/auth/refresh-token", {
      refreshToken,
    });

    return response;
  } catch (error) {
    throw error;
  }
};
