import cookies from "js-cookie";
import config from "../config";

export const getToken = () => {
  return cookies.get("token");
};

export const removeToken = () => {
  cookies.remove("token");
};

export const setToken = (value) => {
  cookies.set("token", value, {
    expires: parseFloat(config.tokenExpires),
  });
};

export const getRefreshToken = () => {
  return cookies.get("refreshToken");
};

export const removeRefreshToken = () => {
  cookies.remove("refreshToken");
};

export const setRefreshToken = (value) => {
  cookies.set("refreshToken", value, {
    expires: parseFloat(config.tokenExpires),
  });
};

export const getUnitSetting = (unitSetting) => {
  if (unitSetting) {
    return {
      unitSetting,
      valueUnitSetting: getValueByUnitSetting(unitSetting),
    };
  }

  unitSetting = cookies.get("unitSetting");
  if (unitSetting == null) {
    unitSetting = "Imperial";
    setUnitSetting("Imperial");
  }
  return { unitSetting, valueUnitSetting: getValueByUnitSetting(unitSetting) };
};

export const removeUnitSetting = () => {
  cookies.remove("unitSetting");
};

export const setUnitSetting = (value) => {
  cookies.set("unitSetting", value);
};

const getValueByUnitSetting = (unitSetting) => {
  if (unitSetting === "Metric") {
    return "km / liters / kPa";
  }
  return "miles / galons / psi";
};
