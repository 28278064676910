import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/axios";

export const fetchHistoryInfo = createAsyncThunk(
  "fetchHistoryInfo",
  async (vinId) => {
    try {
      const response = await api.get(`api/v1/vehicleHistories/info/${vinId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch vehicle history!");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const fetchHistoryRecall = createAsyncThunk(
  "fetchHistoryRecall",
  async (vinId) => {
    try {
      const response = await api.get(`api/v1/vehicleHistories/recall/${vinId}`);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to fetch vehicle history!");
      }
    } catch (error) {
      throw error;
    }
  }
);
