// Sentry initialization should be imported first!
import "./instrument";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { FacebookProvider } from "react-facebook";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import config from "./config";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";

if ("caches" in window) {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={config.googleClientId}>
    <FacebookProvider appId={config.facebookAppId}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HelmetProvider>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </HelmetProvider>
      </ThemeProvider>
    </FacebookProvider>
  </GoogleOAuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
