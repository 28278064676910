const config = {
  apiUrl: window._env_.REACT_APP_API_URL || "http://localhost:3000",
  carMode: window._env_.REACT_APP_CAR_MODE || "test",
  clientId: window._env_.REACT_APP_CLIENT_ID || "",
  redirectUri:
    window._env_.REACT_APP_REDIRECT_URI ||
    "https://javascript-sdk.smartcar.com/v2/redirect?app_origin=http://localhost:8000",
  port: window._env_.PORT || "8000",
  tokenExpires: window._env_.REACT_APP_TOKEN_EXPIRES || "500m",
  refreshTokenExpires: window._env_.REACT_APP_REFRESH_TOKEN_EXPIRES || "30d",
  googleMapApiKey: window._env_.REACT_APP_GOOGLE_MAP_API_KEY || "",
  googleClientId: window._env_.REACT_APP_GOOGLE_CLIENT_ID || "",
  facebookAppId: window._env_.REACT_APP_FACEBOOK_APP_ID || "",
  appleClientId: window._env_.REACT_APP_APPLE_CLIENT_ID || "",
  appleRedirectUrl: window._env_.REACT_APP_APPLE_REDIRECT_URI || "",
  stripePriceId: window._env_.REACT_APP_STRIPE_PRICE_ID || "",
  sentryDns: window._env_.REACT_APP_SENTRY_DNS || "",
  sentryEnvironment: window._env_.REACT_APP_SENTRY_ENVIRONMENT || "staging",
};

export default config;
