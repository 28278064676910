import { createSlice } from "@reduxjs/toolkit";
import { fetchUserInfo, updateUserName, updateUserAvt } from "../api";
import {
  createAsyncActionsMatcher,
  createAsyncActionsHandler,
} from "./handleAsyncAction";

const asyncThunks = [
  { source: fetchUserInfo },
  { source: updateUserName },
  { source: updateUserAvt },
];

const userProfile = createSlice({
  name: "userProfile",
  initialState: {
    data: null,
    status: null,
    error: null,
  },
  reducers: {
    resetUserProfile: (state) => {
      state.data = null;
      state.status = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      createAsyncActionsMatcher(asyncThunks),
      createAsyncActionsHandler(asyncThunks)
    );
  },
});

export const { resetUserProfile } = userProfile.actions;
export default userProfile.reducer;
