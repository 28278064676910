import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import ResponsiveLayout from "../../layout/ResponsiveLayout";
import { getToken } from "../../utils";
import { Hero } from "../../components/landing/Hero";
import { Header } from "../../components/landing/Header";
import { CarSlides } from "../../components/landing/CarSlides";
import { Helmet } from "react-helmet-async";
import Button from "@mui/material/Button";

const StepSections = lazy(() =>
  import("../../components/landing/StepSections").then((module) => ({
    default: module.StepSections,
  }))
);
const FeedBackSections = lazy(() =>
  import("../../components/landing/FeedBackSections").then((module) => ({
    default: module.FeedBackSections,
  }))
);
const Footer = lazy(() =>
  import("../../components/landing/Footer").then((module) => ({
    default: module.Footer,
  }))
);
const FAQSection = lazy(() =>
  import("../../components/landing/FAQSection").then((module) => ({
    default: module.FAQSection,
  }))
);
const FeatureSections = lazy(() =>
  import("../../components/landing/FeatureSections").then((module) => ({
    default: module.FeatureSections,
  }))
);
const PlanSelection = lazy(() =>
  import("../../components/landing/PlanSelection").then((module) => ({
    default: module.PlanSelection,
  }))
);
const ConnectSection = lazy(() =>
  import("../../components/landing/ConnectSection").then((module) => ({
    default: module.ConnectSection,
  }))
);

export default function Landing() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const heroRef = useRef(null);
  const stepSectionsRef = useRef(null);
  const connectSectionRef = useRef(null);
  const faqSectionRef = useRef(null); // Create a ref for FAQSection

  useEffect(() => {
    if (getToken()) {
      navigate("/cars");
    }
  }, [navigate]);

  useEffect(() => {
    // Handle hash navigation on initial load
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));
      if (element) {
        // Scroll to the element with a slight delay to allow rendering
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 0); // You can adjust this delay if necessary
      }
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const heroInView = entries.find(
          (entry) => entry.target === heroRef.current && entry.isIntersecting
        );
        const stepSectionsInView = entries.find(
          (entry) => entry.target === stepSectionsRef.current && entry.isIntersecting
        );
        const connectSectionInView = entries.find(
          (entry) => entry.target === connectSectionRef.current && entry.isIntersecting
        );

        setIsButtonVisible(!(heroInView || stepSectionsInView || connectSectionInView));
      },
      { threshold: 0.5 }
    );

    // Observe relevant sections
    if (heroRef.current) observer.observe(heroRef.current);
    if (stepSectionsRef.current) observer.observe(stepSectionsRef.current);
    if (connectSectionRef.current) observer.observe(connectSectionRef.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (heroRef.current) observer.unobserve(heroRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (stepSectionsRef.current) observer.unobserve(stepSectionsRef.current);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (connectSectionRef.current) observer.unobserve(connectSectionRef.current);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Digital Car Key</title>
        <meta
          name="description"
          content="Unlock your car with a Car Pass Digital Car Key. Smart Car remote control car access, keyless car key convenience, auto remote start, and keyless entry auto for a modern driving experience."
        />
        <link rel="canonical" href="https://carpass.io/" />
        <meta property="og:site_name" content="Digital Car Key" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://carpass.io/" />
        <meta
          property="og:title"
          content="CarPass Digital Key For Car | Smart Car Key"
        />
        <meta
          property="og:description"
          content="Unlock your car with a Car Pass Digital Car Key. Smart Car remote control car access, keyless car key convenience, auto remote start, and keyless entry auto for a modern driving experience."
        />
      </Helmet>

      <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
        <Header />
      </ResponsiveLayout>

      <div ref={heroRef}>
        <Hero />
      </div>

      <CarSlides />

      <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
        <FeatureSections />

        <Suspense fallback={<></>}>
          <div ref={stepSectionsRef}>
            <StepSections />
          </div>
        </Suspense>
      </ResponsiveLayout>

      <Box
        sx={{
          background:
            "linear-gradient(180deg, rgba(225, 235, 240, 0) 17.8%, #E0E6F3 97.96%)",
        }}
      >
        <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
          <Suspense fallback={<div style={{ minHeight: "300px" }}></div>}>
            <FeedBackSections />
          </Suspense>
        </ResponsiveLayout>
      </Box>

      <ResponsiveLayout isMobile={isMobile} isTablet={isTablet}>
        <Suspense fallback={<></>}>
          <PlanSelection />
        </Suspense>

        <Suspense fallback={<></>}>
          <div ref={faqSectionRef}>
            <FAQSection />
          </div>
        </Suspense>

        <Suspense fallback={<></>}>
          <div ref={connectSectionRef}>
            <ConnectSection />
          </div>
        </Suspense>

        <Suspense fallback={<></>}>
          <Footer />
        </Suspense>
      </ResponsiveLayout>

      {isMobile && (
        <Box
          sx={{
            textAlign: "center",
            position: "fixed",
            bottom: 0,
            width: "100%",
            zIndex: 5,
            transition: "transform 0.5s ease-in-out",
            transform: isButtonVisible ? "translateY(0)" : "translateY(100%)",
          }}
        >
          <Button
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "16px",
              border: "none",
              backgroundColor: "#0764FD",
              width: isMobile ? "92%" : "18rem",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0650CA",
              },
              marginBottom: "16px",
            }}
            onClick={() => navigate("/get-started")}
          >
            Connect your car
          </Button>
        </Box>
      )}
    </>
  );
}
