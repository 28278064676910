// Helper function to dynamically import images based on range
const importIcons = (start, end) => {
  return Array.from({ length: end - start + 1 }, (_, index) =>
    import(`../assets/svg/car-brand-icon-${start + index}.svg`)
  );
};

// Import icons from 1 to 21 for aboveIcons
export const aboveIcons = importIcons(1, 21);

// Import icons from 22 to 42 for belowIcons
export const belowIcons = importIcons(22, 42);
