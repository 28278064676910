import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/axios";

export const createPayPalSubscriptionSession = async (planId) => {
  try {
    const response = await api.post(
      "/api/v3/paypalPayments/createSubscriptionSession",
      {
        planId,
      }
    );

    // Get the URL to which the user should be redirected
    const { approvalUrl } = response.data.data;

    // Redirect the user to PayPal's checkout page
    window.location.href = approvalUrl;
  } catch (error) {
    console.error("Error creating PayPal subscription session:", error);
    throw error;
  }
};

export const checkPayPalSubscriptionStatus = createAsyncThunk(
  "checkPayPalSubscriptionStatus",
  async () => {
    try {
      const response = await api.get(
        "/api/v3/paypalPayments/subscription-status"
      );
      return response.data.data;
    } catch (error) {
      console.error("Error when checking PayPal subscription status:", error);
    }
  }
);

export const checkPaypalSession = async (subscriptionId) => {
  try {
    const response = await api.get(
      `api/v3/paypalPayments/check-session?subscriptionId=${subscriptionId}`
    );

    return response.data.data?.isValid;
  } catch (error) {
    console.error("Error when check session:", error);
  }
};

export const cancelPayPalSubscription = async (subscriptionId) => {
  try {
    const response = await api.post(
      "/api/v3/paypalPayments/cancelSubscription",
      {
        subscriptionId,
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error canceling PayPal subscription:", error);
  }
};

export const getPayPalConfigs = async () => {
  try {
    const response = await api.get("/api/v3/paypalPayments/config");
    return response.data.data;
  } catch (error) {
    console.error("Error getting PayPal configs:", error);
  }
};
