import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/axios";

export const fetchUserInfo = createAsyncThunk("fetchUserInfo", async () => {
  try {
    const response = await api.get(`api/v1/users/me/detail`);
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error("Failed to fetch user profile!");
    }
  } catch (error) {
    throw error;
  }
});

export const updateUserName = createAsyncThunk(
  "updateUserName",
  async (name) => {
    try {
      const response = await api.put(`/api/v1/users/me`, {
        name,
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to update user name!");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const updateUserAvt = createAsyncThunk(
  "updateUserAvt",
  async (formData) => {
    try {
      const response = await api.put(`/api/v1/users/me/avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error("Failed to update user avt!");
      }
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAccount = async () => {
  try {
    const response = await api.delete("api/v1/users/me/delete-account");
    if (response.status === 200) {
      return true;
    } else {
      throw new Error("Failed to delete user!");
    }
  } catch (error) {
    throw error;
  }
};
