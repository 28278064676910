import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";
import heroBackgroundMobile from "../../assets/images/hero-bg-mobile.webp";
import heroBackgroundDesktop from "../../assets/images/hero-bg-desktop.webp";
import heroCarDesktop from "../../assets/images/hero-car-desktop.webp";
import heroCarMobile from "../../assets/images/hero-car-mobile.webp";

export const Hero = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));           
  const heroBackgroundImage = isMobile
    ? heroBackgroundMobile
    : heroBackgroundDesktop;

  return (
    <>
      <div id="hero">
        <Box
          sx={{
            padding: { xs: "108px 0 106px 0", md: "144px 0 128px 0" },
            minHeight: { xs: "600px", sm: "calc(100vh - 36px)", md: "100vh" },
            position: "relative",
            overflow: "hidden",
            display: "flex",
            alignItems: { xs: "flex-end", lg: "center" },
            background: `url(${heroBackgroundImage}) no-repeat center/cover`,
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{
              maxWidth: { xs: "100%", lg: "1152px" },
              margin: "0 auto",
              padding: { xs: "0 16px", md: "0 20px", lg: 0 },
              width: "100%",
              textAlign: { xs: "center", lg: "left" },
              marginBottom: isMobile ? "-6rem" : "0",
              zIndex: 1,
            }}
          >
            <Typography
              variant="h1" component="h1"
              sx={{
                fontSize: { xs: "48px", md: "64px" },
                fontWeight: "600",
                lineHeight: { xs: "56px", md: "64px" },
                color: "#021056",
              }}
            >
              Digital Car Key
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "16px", md: "20px" },
                fontWeight: "500",
                lineHeight: { xs: "20px", md: "24px" },
                color: "#021056",
                width: { xs: "100%", lg: "50%" },
                marginTop: "8px",
              }}
            >
              Smartly connect and remote control your vehicles
            </Typography>
            <Button
              sx={{
                color: "#FFFFFF",
                fontWeight: "600",
                fontSize: "16px",
                lineHeight: "16px",
                border: "none",
                backgroundColor: "#0764FD",
                width: isMobile ? "92%" : "18rem",
                height: "48px",
                marginTop: "28px",
                "&:hover": {
                  backgroundColor: "#0650CA",
                },
              }}
              onClick={() => navigate("/get-started")}
            >
              Connect your car
            </Button>
          </Box>

          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
              right: 0,
              bottom: 0,
              overflow: "hidden",
              zIndex: 0,
            }}
          >
            {/* Car Image with Lazy Loading */}
            <Box
              component="img"
              src={heroCarDesktop}
              alt="Car"
              sx={{
                width: "50%",
                height: "auto",
                position: "absolute",
                right: "10%",
                bottom: 5,
                display: { xs: "none", lg: "block" },
                zIndex: 2,
                animation: "fadeIn 1.0s ease-in-out",
                "@keyframes fadeIn": {
                  from: { opacity: 0 },
                  to: { opacity: 1 },
                },
              }}
            />

            {/* Car Image with Lazy Loading for mobile  */}
            <Box
              component="img"
              src={heroCarMobile}
              alt="Car"
              sx={{
                width: "120%",
                height: "auto",
                position: "absolute",
                right: "-22%",
                bottom: { xs: 85, sm: 0 },
                display: { xs: "block", lg: "none" },
                zIndex: 2,
                animation: "fadeIn 2s ease-in-out",
                "@keyframes fadeIn": {
                  from: { opacity: 0 },
                  to: { opacity: 1 },
                },
              }}
            />

            {/* Gradient Layer for the White Bottom Effect */}
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                height: { xs: "40%", sm: "30%" },
                bottom: "0%",
                left: 0,
                background: {
                  xs: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 45%)`,
                  sm: `linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1) 95%)`,
                },
                zIndex: 3,
              }}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};
