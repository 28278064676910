import React, { useEffect, Suspense, lazy } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Landing from "./pages/landing";

// Lazy load the pages to optimize performance
const Support = lazy(() => import("./pages/support"));
const Legal = lazy(() => import("./pages/legal"));
const Privacy = lazy(() => import("./pages/privacy"));
const SignInPage = lazy(() =>
  import("./pages/login/LoginPage").then((module) => ({
    default: module.SignInPage,
  }))
);
const SignUpPage = lazy(() =>
  import("./pages/login/SignupPage").then((module) => ({
    default: module.SignUpPage,
  }))
);
const VerifyPage = lazy(() =>
  import("./pages/login/VerifyPage").then((module) => ({
    default: module.VerifyPage,
  }))
);
const OneTimeLogin = lazy(() => import("./pages/login/OneTimeLogin"));
const UnRegisteredPage = lazy(() =>
  import("./pages/login/UnRegisteredPage").then((module) => ({
    default: module.UnRegisteredPage,
  }))
);
const RegisteredPage = lazy(() =>
  import("./pages/login/RegisteredPage").then((module) => ({
    default: module.RegisteredPage,
  }))
);
const SmartCarCompatible = lazy(() =>
  import("./pages/car/SmartCarCompatible").then((module) => ({
    default: module.SmartCarCompatible,
  }))
);
const GetStarted = lazy(() => import("./pages/car/GetStarted"));
const AddCar = lazy(() => import("./pages/car/AddCar"));
const Congratulations = lazy(() =>
  import("./pages/checkout/Congratulations").then((module) => ({
    default: module.Congratulations,
  }))
);
const PaywallPage = lazy(() =>
  import("./pages/checkout/PaywallPage").then((module) => ({
    default: module.PaywallPage,
  }))
);
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const Layout = lazy(() =>
  import("./layout/Layout").then((module) => ({ default: module.Layout }))
);
const SmartCarListPage = lazy(() =>
  import("./pages/car/SmartCarListPage").then((module) => ({
    default: module.SmartCarListPage,
  }))
);
const SmartCarPair = lazy(() =>
  import("./pages/car/SmartCarPair").then((module) => ({
    default: module.SmartCarPair,
  }))
);
const SmartCarDetailPage = lazy(() =>
  import("./pages/car/SmartCarDetailPage").then((module) => ({
    default: module.SmartCarDetailPage,
  }))
);
const HomeDetailPage = lazy(() =>
  import("./pages/car/HomeDetailPage").then((module) => ({
    default: module.HomeDetailPage,
  }))
);
const StatusDetailPage = lazy(() =>
  import("./pages/car/StatusDetailPage").then((module) => ({
    default: module.StatusDetailPage,
  }))
);
const MapDetailPage = lazy(() =>
  import("./pages/car/MapDetailPage").then((module) => ({
    default: module.MapDetailPage,
  }))
);
const ServiceDetailPage = lazy(() =>
  import("./pages/car/ServiceDetailPage").then((module) => ({
    default: module.ServiceDetailPage,
  }))
);
const CenterDetailPage = lazy(() =>
  import("./pages/car/CenterDetailPage").then((module) => ({
    default: module.CenterDetailPage,
  }))
);
const ScheduleFormPage = lazy(() =>
  import("./pages/car/ScheduleFormPage").then((module) => ({
    default: module.ScheduleFormPage,
  }))
);
const ProfilePage = lazy(() =>
  import("./pages/profile/ProfilePage").then((module) => ({
    default: module.ProfilePage,
  }))
);
const AccountPage = lazy(() =>
  import("./pages/profile/AccountPage").then((module) => ({
    default: module.AccountPage,
  }))
);
const PlanBillingPage = lazy(() =>
  import("./pages/profile/PlanBillingPage").then((module) => ({
    default: module.PlanBillingPage,
  }))
);
const UnitPage = lazy(() =>
  import("./pages/profile/UnitPage").then((module) => ({
    default: module.UnitPage,
  }))
);
const SafariTutorialPage = lazy(() => import("./pages/SafariTutorialPage"));
const InformationPage = lazy(() =>
  import("./pages/car/InformationPage").then((module) => ({
    default: module.InformationPage,
  }))
);
const SpecificationPage = lazy(() =>
  import("./pages/car/SpecificationPage").then((module) => ({
    default: module.SpecificationPage,
  }))
);
const ActiveSafetySystemPage = lazy(() =>
  import("./pages/car/ActiveSafetySystemPage").then((module) => ({
    default: module.ActiveSafetySystemPage,
  }))
);
const RecallPage = lazy(() =>
  import("./pages/car/RecallPage").then((module) => ({
    default: module.RecallPage,
  }))
);
const VehicleManualPage = lazy(() =>
  import("./pages/car/VehicleManualPage").then((module) => ({
    default: module.VehicleManualPage,
  }))
);
const AgentPage = lazy(() =>
  import("./pages/car/AgentPage").then((module) => ({
    default: module.AgentPage,
  }))
);
const UpgradePlan = lazy(() =>
  import("./pages/checkout/UpgradePlan").then((module) => ({
    default: module.UpgradePlan,
  }))
);
const ChangePlan = lazy(() =>
  import("./pages/checkout/ChangePlan").then((module) => ({
    default: module.ChangePlan,
  }))
);

const router = createBrowserRouter([
  { path: "", element: <Landing /> },
  { path: "support", element: <Support /> },
  { path: "legal", element: <Legal /> },
  { path: "privacy", element: <Privacy /> },
  { path: "login", element: <SignInPage /> },
  { path: "signup", element: <SignUpPage /> },
  { path: "verify", element: <VerifyPage /> },
  { path: "/one-time-login", element: <OneTimeLogin /> },
  { path: "/not-existing-email", element: <UnRegisteredPage /> },
  { path: "/registered-email", element: <RegisteredPage /> },
  { path: "/check-compatible", element: <SmartCarCompatible /> },
  { path: "/get-started", element: <GetStarted /> },
  { path: "/cars/add", element: <AddCar /> },
  { path: "/congratulations", element: <Congratulations /> },
  { path: "/paywall", element: <PaywallPage /> },
  { path: "/upgrade-plan", element: <UpgradePlan /> },
  { path: "/change-plan", element: <ChangePlan /> },
  { path: "*", element: <ErrorPage /> },
  {
    element: <Layout />,
    children: [
      { path: "/cars", element: <SmartCarListPage /> },
      { path: "/cars/:id/pair", element: <SmartCarPair /> },
      { path: "/cars/:id", element: <Navigate to="home" /> },
      {
        path: "/cars/:id/home",
        element: <SmartCarDetailPage />,
        children: [{ path: "", element: <HomeDetailPage /> }],
      },
      {
        path: "/cars/:id/status",
        element: <SmartCarDetailPage />,
        children: [{ path: "", element: <StatusDetailPage /> }],
      },
      {
        path: "/cars/:id/map",
        element: <SmartCarDetailPage />,
        children: [{ path: "", element: <MapDetailPage /> }],
      },
      {
        path: "/cars/:id/service",
        element: <SmartCarDetailPage />,
        children: [{ path: "", element: <ServiceDetailPage /> }],
      },
      {
        path: "/cars/:id/center",
        element: <SmartCarDetailPage />,
        children: [{ path: "", element: <CenterDetailPage /> }],
      },
      { path: "/cars/:id/service/form", element: <ScheduleFormPage /> },
      { path: "/profile", element: <Navigate to="account" /> },
      {
        path: "/profile/account",
        element: <ProfilePage />,
        children: [{ path: "", element: <AccountPage /> }],
      },
      {
        path: "/profile/plan",
        element: <ProfilePage />,
        children: [{ path: "", element: <PlanBillingPage /> }],
      },
      {
        path: "/profile/preference",
        element: <ProfilePage />,
        children: [{ path: "", element: <UnitPage /> }],
      },
      { path: "/cars/safari-tutorial", element: <SafariTutorialPage /> },
      {
        path: "/cars/:id/center/:vinId/information",
        element: <InformationPage />,
      },
      {
        path: "/cars/:id/center/:vinId/specification",
        element: <SpecificationPage />,
      },
      {
        path: "/cars/:id/center/:vinId/specification/active",
        element: <ActiveSafetySystemPage />,
      },
      { path: "/cars/:id/center/:vinId/recall", element: <RecallPage /> },
      {
        path: "/cars/:id/center/:vinId/manual",
        element: <VehicleManualPage />,
      },
      { path: "/cars/agents", element: <AgentPage /> },
    ],
  },
]);

function App() {
  useEffect(() => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        }
      >
        <RouterProvider router={router} />
      </Suspense>
    </Provider>
  );
}

export default App;
